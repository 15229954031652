@import '../@styles/variables';

.SochiSpoilerView {
    &__spoiler-button-container {
        width: 125px;
        @include breakpoints(responsive) {
            width: 90px;
        }
    }

    &__search {
        @include breakpoints(responsive) {
            & .MuiInputBase-root {
                height: 30px;
                padding: 5px 5px 5px 10px;
                line-height: 0.875em;
                font-size: 14px;
            }
        }
    }

    &__search-icon {
        font-size: 24px !important;

        @include breakpoints(responsive) {
            font-size: 18px !important;
        }
    }
}
