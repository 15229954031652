@import '../@styles/variables';

.SochiInfoPanel {
    align-items: center;
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    margin: 40px 0;
    min-height: 68px;
    padding: 14px 20px;
    @include breakpoints(responsive) {
        margin: 10px 0 20px;
    }
    @include breakpoints(smartphone) {
        flex-direction: column;
    }

    &--green {
        background: $color-green;
        color: $color-white;
    }

    &--orange {
        background: $color-orange;
        color: $color-white;
    }

    &__text {
        font-family: $catamaran;
        font-size: 18px;
        font-weight: 600;
        @include breakpoints(responsive) {
            font-size: 14px;
        }
    }

    &__buttons {
        @include breakpoints(responsive) {
            margin-top: 14px;
        }

        button {
            margin: 0 0 0 12px;
        }
    }
}
