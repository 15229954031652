@import '../../../../../@sochi-components/@styles/variables';

.CreateWizardOption {
    font-family: $catamaran;
    background: $color-white;
    border-radius: 8px;
    display: flex;
    flex-direction: row;

    &__info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 30px;
    }

    &__title {
        color: $color-blue;
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 18px;
    }

    &__subtitle {
        font-size: 18px;
        color: $color-black;
    }

    &__button {
        width: 32px;
        min-width: 32px;
        font-size: 14px;
        background: $color-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &:hover {
            background: $color-blue-light;
        }

        @include breakpoints(responsive) {
            font-size: 18px;
        }
    }
}
