@import '../@styles/variables';

.SochiBackButton {
    background: none;
    border: none;
    cursor: pointer;
    margin: -3px 0 33px -4px;
    font-size: 21px;

    @include breakpoints(responsive) {
        font-size: 28px;
        margin: -6px 0 -1px -5px;
    }
}
