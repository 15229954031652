@import '../@styles/variables';

.TransportReceiptDialog {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include breakpoints(responsive) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        gap: 10px;

        .SochiCloseDialogButton {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    @include breakpoints(tablet) {
        grid-template-columns: 1fr 1fr;
        gap: unset;
    }

    &__image {
        max-height: calc(100vh - 90px);
        padding: 0;

        @include breakpoints(responsive) {
            margin-top: 36px;
            max-height: unset;
        }

        @include breakpoints(tablet) {
            margin-top: 50px;
            max-height: calc(100vh - 58px);
        }

        &--with-pdf {
            .SochiPdfPreview {
                width: 425px;
                min-width: 0;
                height: 580px;
                margin: auto;

                @include breakpoints(responsive) {
                    width: 340px;
                    height: 460px;
                }

                @include breakpoints(tablet) {
                    height: auto;
                    width: 100%;
                    max-height: calc(100vh - 110px);
                }
            }
        }
    }

    &__information {
        display: flex;
        flex-direction: column;
        font-family: $catamaran;
        justify-content: center;
        padding: 40px 28px;
        gap: 16px;

        @include breakpoints(responsive) {
            padding: 20px 10px;
        }
    }

    &__title {
        color: $color-black;
        font-size: 28px;
        font-weight: 600;
        line-height: 1em;
        margin-bottom: 8px;

        @include breakpoints(responsive) {
            font-size: 24px;
        }

        @include breakpoints(responsive) {
            font-size: 24px;
        }
    }
}
