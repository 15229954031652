@import '../../../../../@sochi-components/@styles/variables';

.LandfillCertificates {
    &__documents-cards {
        @include DocumentCardsWrapper;
    }

    &__crop-cards {
        @include DocumentCropCards;
    }
}
