@import '../@styles/variables';

.SideBarTitle {
    &__title {
        font-weight: bold;
        font-size: 28px;
        line-height: 1.3em;
        @include breakpoints(responsive) {
            font-size: 24px;
        }
    }
    &__subtitle {
        font-size: 28px;
        margin-bottom: 10px;
        margin-top: 0;
        opacity: 0.4;
        @include breakpoints(responsive) {
            font-size: 14px;
        }
    }
}
