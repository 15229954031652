@import '../../../../../@sochi-components/@styles/variables';
.DeliveriesTable {
    padding-bottom: 10px;

    &__view {
        padding-top: 2px;
        display: inline-flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        font-size: 18px;

        &--disabled {
            color: $color-gray-light-2;
            cursor: default;
        }

        @include breakpoints(responsive) {
            font-size: 14px;
        }
    }

    &__table {
        max-height: calc(100vh - 100px);
        overflow-y: scroll;

        @include breakpoints(responsive) {
            max-height: calc(100vh - 250px);
        }
    }

    &__sender-notes {
        padding: 0 4px 0 0 !important;
    }

    &__driver-notes {
        padding: 0 0 0 4px !important;
    }
}
