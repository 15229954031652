@import '../../@styles/variables';

.NoDocumentation {
    background: $color-white;
    border-top: 2px solid $color-blue-dark;
    width: 300px;
    padding: 24px 10px;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: $color-text-black-transparent;
    text-align: center;
    font-family: $catamaran;
    letter-spacing: 0.2px;
}
