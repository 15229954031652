@import '../../../../@sochi-components/@styles/variables';

.ProjectOffers {
    &__wrapper {
        @include pageWrapper;
    }

    &__filter {
        padding: 20px 0;
        display: flex;
        justify-content: flex-end;
    }

    &__view-offer-info {
        cursor: pointer;

        font-size: 24px;

        @include breakpoints(responsive) {
            font-size: 18px;
        }
    }
}
