@import '../../../../@sochi-components/@styles/variables';

.UserViewLayout {
    background: $color-gray-background;
    position: relative;
    height: 100%;

    overflow: auto;
}

// TODO it seems useless, maybe it should be removed
.sochi-row,
.sochi-col {
    display: flex;
    margin: 2px;
    .sochi-flex-1 {
        flex: 1;
    }
}

.sochi-row {
    flex-direction: row;
}
.sochi-col {
    flex-direction: column;
}
