@import '../../../../../@sochi-components/@styles/variables';

.ProjectAddEditNotes {
    width: 600px;
    padding: 0 20px 20px 20px;

    @include breakpoints(tablet) {
        width: 100%;
    }

    @include breakpoints(smartphone) {
        width: 100%;
    }

    @include breakpoints(small-smartphone) {
        width: 340px;
        padding: 0 10px 10px 10px;
    }

    &__title {
        margin: 10px 0 10px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__notes-edit-form__close {
        width: 97%;
    }

    &__form-text {
        margin-bottom: 16px !important;
        @include breakpoints(small-smartphone) {
            width: 320px;
        }
    }

    &__form-buttons {
        width: 220px;
        display: flex;
        margin-right: 0;
        margin-left: auto;
        justify-content: space-between;

        @include breakpoints(tablet) {
            width: 100%;
        }

        @include breakpoints(smartphone) {
            width: 100%;
        }
    }
}
