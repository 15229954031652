@import '../../../../../../@sochi-components/@styles/variables';

.LandfillWorkTime {
    font-family: $catamaran;

    &__non-enlarged-heading {
        line-height: 1.1;
        margin-bottom: 24px;

        @include breakpoints(smartphone) {
            margin-bottom: 26px;
        }
    }
}
