@import '../../../@sochi-components/@styles/variables';

.Error {
    user-select: none;
    padding: 0;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

    display: flex;
    position: fixed;
    width: 60%;
    left: 0;
    right: 0;
    bottom: 0;

    margin: auto;

    background-color: $color-red-dark;
    border-radius: 2px;
    color: $color-white;

    &__error {
        display: flex;
        flex: 1;
        padding: 20px;
        font-size: 1.1em;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }

    &__text {
        flex: 1;
        align-self: center;
        cursor: pointer;
    }

    &__button {
        flex: 1;
        border: 1px solid $color-white;
        align-self: center;
        font-size: 0.9em;
        font-weight: 600;
        padding: 8px 20px;
        text-transform: uppercase;
        margin-right: 20px;
        margin-left: 20px;
        color: $color-red-dark;
        background-color: $color-white;
        position: absolute;
        right: 4px;
        top: 12px;
        border-radius: 0;
    }
}
