@import '../../../../../@sochi-components/@styles/variables';

.LandfillWeightReceipts {
    padding-bottom: 10px;

    &__view {
        padding-top: 2px;
        display: inline-flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        font-size: 18px;

        &--disabled {
            color: $color-gray-light-2;
            cursor: default;
        }

        @include breakpoints(responsive) {
            font-size: 14px;
        }
    }

    &__table {
        max-height: calc(100vh - 100px);
        overflow-y: scroll;

        @include breakpoints(responsive) {
            max-height: calc(100vh - 250px);
        }
    }

    &__comment-column {
        max-width: 100px !important;
        @include Ellipsis(100px);

        @include breakpoints(desktop) {
            max-width: 15vw !important;
            @include Ellipsis(15vw);
        }
    }

    &__serial-number-column {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    &__direction-icon {
        color: $color-gray-dark-3;
    }
}
