@import '../../@styles/variables';

.SochiCloseDialogButton {
    align-items: center;
    display: flex;
    height: 48px;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    z-index: $z-index-basic;

    &--mobileOnly {
        display: none;

        @include breakpoints(responsive) {
            display: flex;
        }
    }

    &__button {
        position: relative;
        top: 12px;
        right: 14px;
        padding: 10px;

        @include breakpoints(tablet) {
            top: 2px;
        }
    }
}
