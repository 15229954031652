@import '../../../../@sochi-components/@styles/variables';

.ProjectOfferModal {
    &__view-offer-fortnox {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 24px;
    }

    &__arrow-right-icon {
        font-size: 24px;
        @include breakpoints(responsive) {
            font-size: 18px;
        }
        margin-left: 3px;
        margin-right: -5px;
    }

    &__status-td {
        color: $color-gray;
    }

    &__action-info-text {
        float: right;
        margin: 10px 0;
        color: $color-blue;
        font-family: $catamaran;
    }

    &__button-spacer {
        flex-grow: 1;
    }

    &__offers-padding {
        padding: 25px 55px 10px 10px !important;
    }

    & .ButtonGroup {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
    }

    &__table {
        @include TableInModal;
    }
}
