@import '../@styles/variables';

.SochiTooltip {
    display: inline-block;
    position: relative;

    &__tool-tip {
        @include SimpleCard($color-status-green);
        position: absolute;
        width: 250px;
        padding: 16px;
        font-size: 16px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
        z-index: 20;
        display: none;
        pointer-events: none;

        &--open {
            display: block;
        }
    }
}
