@import '../../../../../../@sochi-components/@styles/variables';

.DumpLoadDates {
    display: flex;

    &__date {
        display: flex;
        flex-direction: column;
        margin-right: 12px;
        width: 168px;
    }
    &__label {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        line-height: 30px;
        color: $color-black;
        width: 100%;
    }
}
