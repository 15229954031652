@import '../../../../@sochi-components/@styles/variables';
.SignupPage {
    &__form {
        @include AuthForm;
        @include DarkBackgroundInputLabel;
    }
    &__checkbox-group {
        display: flex;
        gap: 12px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 76px;
    }

    &__link {
        cursor: pointer;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    &__buttons {
        margin-top: 20px;
    }

    &__captcha {
        margin-top: 10px;
    }
}
