@import '../../@styles/variables';

.ScheduleChart {
    flex-basis: 100%;
    font-family: $catamaran;

    &__chart {
        display: flex;
        background: $color-white;
        border-top: 2px solid $color-blue-dark;
        padding: 74px 40px 22px 22px;
        position: relative;

        @include breakpoints(responsive) {
            padding: 48px 20px 0 0;
        }
    }

    &__series-names {
        display: flex;
        flex-direction: column;
        flex-basis: 300px;
        max-width: 300px;

        @include breakpoints(responsive) {
            flex-basis: auto;
        }

        &--mobile {
            flex-basis: 100%;
            max-width: 100%;
            display: none;
            background: $color-white;

            @include breakpoints(responsive) {
                display: flex;
                padding: 8px 10px;
                margin-bottom: 20px;
            }
        }
    }

    &__series-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: $color-text-black;
        font-weight: bold;
        padding: 0 16px;
        height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 0;

        @include breakpoints(responsive) {
            justify-content: normal;
            margin-bottom: 24px;
            padding: 0 6px;
            text-align: center;
            font-size: 14px;
        }

        &--mobile {
            text-align: left;

            @include breakpoints(responsive) {
                margin: 0;
                height: auto;
                line-height: 1.5em;
            }
        }

        &:first-child {
            margin-top: 12px;
        }

        &--orange {
            background: transparentize($color-chart-orange, 0.8);
        }

        &--green {
            background: transparentize($color-chart-green, 0.8);
        }

        &--gray {
            background: transparentize($color-chart-gray, 0.8);
        }

        &--black {
            background: transparentize($color-chart-black, 0.8);
        }

        &--blue {
            background: transparentize($color-chart-blue, 0.8);
        }
    }

    &__series-index {
        display: none;

        @include breakpoints(responsive) {
            display: inline;
        }
    }

    &__series-full-name {
        @include breakpoints(responsive) {
            display: none;
        }

        &--mobile {
            @include breakpoints(responsive) {
                display: inline;
                margin-left: 4px;
            }
        }

        @include Ellipsis(100%);
    }

    &__series-add-info {
        text-align: right;
        color: $color-black-transparent;

        @include breakpoints(responsive) {
            display: none;
        }

        &--mobile {
            @include breakpoints(responsive) {
                display: inline;
                margin-left: 4px;
            }
        }
    }

    &__series-comment {
        text-align: right;
        font-size: 16px;
        line-height: 16px;
        padding: 0;
        margin: 3px 16px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 18px;

        @include breakpoints(responsive) {
            display: none;
        }

        &--mobile {
            @include breakpoints(responsive) {
                display: block;
                padding-left: 18px;
                font-size: 14px;
                text-align: left;
                margin: 0 0 8px;
                color: $color-black-transparent;
            }
        }
    }

    &__chart-columns {
        flex-basis: 1110px;
        flex-direction: row;
        display: flex;
        flex-grow: 2;
        position: relative;
        border-left: 1px solid $color-blue-dark;
        border-right: 1px solid $color-blue-dark;
    }

    &__left-arrow {
        position: absolute;
        left: -15px;
        top: -43px;
        cursor: pointer;
        z-index: 1;
        color: $color-blue;
        font-size: 28px;

        &--disabled {
            cursor: auto;
            pointer-events: none;
        }
    }

    &__right-arrow {
        position: absolute;
        right: -15px;
        top: -43px;
        cursor: pointer;
        z-index: 1;
        color: $color-blue;
        font-size: 28px;

        &--disabled {
            cursor: auto;
            pointer-events: none;
        }
    }

    &__columns-counter {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        width: 100%;
    }

    &__column {
        text-align: center;
        border-right: 1px dotted $color-blue-dark;
        position: relative;
        transition: 0.2s;

        &:last-child {
            border-right: none;
        }
    }

    &__day-pointer {
        position: absolute;
        top: 0;
        height: 100%;
        width: 1px;
        display: block;
        background: $color-red;
    }

    &__column-header {
        position: absolute;
        top: -34px;
        width: 100%;

        @include breakpoints(responsive) {
            top: -20px;
        }

        &--multi {
            top: -51px;

            @include breakpoints(responsive) {
                top: -30px;
            }
        }
    }

    &__header-text {
        font-size: 18px;
        color: $color-text-black-transparent;

        @include breakpoints(responsive) {
            font-size: 14px;
        }

        &--accent {
            color: $color-black;
        }
    }

    &__column-cell {
        height: 32px;
        margin: 12px 0 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        &--clickable {
            cursor: pointer;

            &:hover {
                filter: brightness(90%);
            }
        }

        &--delivered {
        }

        &--planned {
            opacity: 0.6;
        }

        &--orange {
            background: $color-chart-orange;
        }

        &--green {
            background: $color-chart-green;
        }

        &--gray {
            background: $color-chart-gray;
        }

        &--black {
            background: $color-chart-gray;
        }

        &--blue {
            background: $color-chart-blue;
        }

        &--stub {
            opacity: 0;
            cursor: default;
        }
    }
}
