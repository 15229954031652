@import '../../../../@sochi-components/@styles/variables';

.LandfillInfo {
    @include pageWrapper;

    font-family: $catamaran;
    color: $color-text-black;

    &__button-group {
        display: flex;
        justify-content: flex-end;

        @include breakpoints(tablet) {
            flex-direction: column-reverse;
        }

        @include breakpoints(smartphone) {
            flex-direction: column-reverse;
        }

        button:not(:last-child) {
            margin-right: 16px;

            @include breakpoints(tablet) {
                margin-right: 0;
                margin-top: 12px;
            }

            @include breakpoints(smartphone) {
                margin-right: 0;
                margin-top: 12px;
            }
        }
    }

    &__container {
        padding-top: 12px;
        display: grid;
        column-gap: 20px;
        row-gap: 16px;

        grid-template-columns: 320px 1fr 320px;
        grid-template-rows: auto auto 1fr;

        @include breakpoints(tablet) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 372px auto auto auto 1fr;
        }

        @include breakpoints(smartphone) {
            grid-template-columns: 1fr;
            grid-template-rows: 372px auto;
            row-gap: 0;
        }
    }

    &__left-sidebar {
        grid-column: 1;
        grid-row: 1 / span 3;

        @include breakpoints(tablet) {
            grid-row: 1 / span 4;
        }

        @include breakpoints(smartphone) {
            grid-row: 2;
        }
    }

    &__opening-hours {
        grid-column: 3;
        grid-row: 1;

        @include breakpoints(tablet) {
            grid-column: 2;
            grid-row: 4 / span 2;
        }

        @include breakpoints(smartphone) {
            grid-column: 1;
            grid-row: 6;
        }
    }

    &__map {
        grid-column: 2;
        grid-row: 1;
        position: relative;

        @include breakpoints(smartphone) {
            grid-column: 1;
        }

        & .Map {
            width: 100%;
            height: 100%;

            &__container {
                width: 100%;
                height: 100%;
                top: 0;
            }
        }
    }

    &__comments {
        grid-column: 2;
        grid-row: 2;

        margin-bottom: -10px;

        @include breakpoints(smartphone) {
            grid-column: 1;
            grid-row: 4;

            margin-bottom: 10px;
        }
    }

    &__description {
        grid-column: 2;
        grid-row: 3;

        margin-bottom: -10px;

        @include breakpoints(smartphone) {
            grid-column: 1;
            grid-row: 5;

            margin-bottom: 10px;
        }
    }

    &__permit-files {
        grid-column: 3;
        grid-row: 2 / span 2;

        @include breakpoints(tablet) {
            grid-column: 1;
            grid-row: 5;
            align-self: start;
        }

        @include breakpoints(smartphone) {
            grid-column: 1;
            grid-row: 3;

            margin-bottom: 16px;
        }
    }
}
