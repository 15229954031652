@import '../../@styles/variables';

.SochiComponentDialog {
    background: $color-gray-background;
    display: block;
    position: absolute;
    top: 40px;
    left: 50%;
    max-width: 100%;
    min-width: 520px;
    transform: translateX(-50%);

    @include breakpoints(responsive) {
        min-width: auto;
        width: 100%;
        top: 0;
        min-height: 100%;
    }
}
