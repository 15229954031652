@import '../@styles/variables';

.SochiLoader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: $color-blue-dark;
    background: none;
    border: none;
    padding-left: 0;
    padding-right: 0;

    &--hover {
        display: block;
        height: auto;
    }

    &__loader-round {
        width: 32px;
        height: 32px;
        display: inline-block;
        border: 2px dashed $color-blue-dark;
        animation-name: rotate;
        border-radius: 100%;
        margin-right: 6px;
        animation-iteration-count: infinite;
        animation-duration: 4s;
        animation-timing-function: linear;
    }

    &__hover-panel {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 16px 16px rgba(0, 0, 0, 0.1);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
