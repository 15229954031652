@import '../../../../../@sochi-components/@styles/variables';

.MaterialCard {
    @include SimpleCard($color-black);
    container-type: inline-size;

    padding: 16px;
    position: relative;
    font-family: $catamaran;
    font-size: 18px;
    color: $color-text-black-transparent;
    margin-bottom: 16px;

    @include breakpoints(tablet) {
        font-size: 16px;
    }

    &__title {
        color: $color-text-black;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 8px;
    }

    &__basic-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__left-column {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    &__right-column {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }

    &__contamination-info {
        width: 100px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid;
        color: $color-text-black;
        padding-left: 8px;
        background-color: transparent;
        border-color: $color-text-black-transparent;
    }

    &__comment {
        color: $color-text-black-transparent;
        min-height: 1.25em;
        line-height: 1.25;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 16px;
    }

    &__status {
        float: right;
        color: $color-orange;
        font-weight: bold;

        &--onHold {
            color: $color-text-black-transparent;
        }
    }

    &__toc-block {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    &__toc-value {
        color: $color-black;
        font-weight: bold;
    }

    &__invasive-species {
        font-weight: bold;
    }

    &__prices-block {
        display: grid;
        grid-template-columns: auto auto auto;
        align-items: end;
        column-gap: 16px;
        row-gap: 8px;
        margin-bottom: 16px;
    }

    &__price-netto {
        color: $color-text-black;
        font-weight: bold;
        margin-right: 16px;
    }

    &__price-brutto {
        font-weight: bold;
        margin-right: 16px;
    }

    &__footer {
        display: grid;
        grid-template-columns: auto minmax(225px, 1fr);
        gap: 8px;
        align-items: end;

        & > * {
            margin-top: 0;
        }
    }

    &__current-amount {
        line-height: 24px;
        color: $color-text-black;
        font-weight: bold;

        &-value {
            white-space: nowrap;
        }
    }

    @container (max-width: 400px) {
        &__prices-block {
            grid-template-columns: 1fr;
        }

        &__price-element > * {
            &:not(:first-of-type) {
                padding-left: 8px;
            }
        }

        &__footer {
            grid-template-columns: 1fr;
        }
    }
}
