@import '../../../../../@sochi-components/@styles/variables';

.Analysis {
    &__analysis-cards {
        @include DocumentCardsWrapper;
    }
    &__crop-cards {
        @include DocumentCropCards;
    }
}
