@import '../../@styles/variables';

.SochiMessageDialog {
    width: 50vw;
    background: $color-gray-background;
    display: block;
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoints(responsive) {
        width: 80vw;
    }

    @include breakpoints(tablet) {
        &__dialog-message {
            max-width: calc(100% - 50px);
        }
    }
}
