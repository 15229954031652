@import '../../@styles/variables';

.DeliveryChart {
    display: block;

    height: 168px;
    margin-bottom: 38px;
    width: 100%;
    margin-right: 12px;
    position: relative;
    @include breakpoints(responsive) {
        margin: 0 0 6px;
        background: $color-white;
        padding: 20px 20px 30px;
        height: auto;
    }
    &__body {
        border-left: 1px solid $color-blue-dark;
        border-right: 1px solid $color-blue-dark;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-gap: 0;
    }
    &__column {
        justify-content: stretch;
        border-right: 1px dotted $color-black-transparent;
        position: relative;
        &:nth-child(10) {
            border-right: none;
        }
    }
    &__value {
        text-align: center;
        font-size: $font-size-main;
        color: $color-black;
        font-weight: normal;
        font-family: $catamaran;
        width: 100%;
        display: block;
        @include breakpoints(responsive) {
            font-size: 15px;
        }
        @include breakpoints(smartphone) {
            display: none;
        }
    }
    &__brick-wrapper {
        position: relative;
        height: 135px;
        margin: 7px 0 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    &__delivered {
        width: 32px;
        position: absolute;
        background: $color-green;
        &--inProgress {
            background: $color-orange;
        }
        @include breakpoints(smartphone) {
            width: 22px;
        }
    }

    &__planned {
        position: absolute;
        width: 40px;
        background: $color-gray-light;
        transform: translateX(4px);
        @include breakpoints(smartphone) {
            width: 24px;
            transform: translateX(1px);
        }
    }
    &__column-number {
        position: absolute;
        left: 50%;
        bottom: -38px;
        transform: translateX(-50%);
        text-align: center;
        font-size: $font-size-main;
        color: $color-black-transparent;
        font-family: $catamaran;
        @include breakpoints(responsive) {
            font-size: 10px;
            bottom: -18px;
        }
    }
    &__arrow-left {
        font-size: 28px;
        left: -14px;
        bottom: -50px;
        position: absolute;
        cursor: pointer;
        &--disabled {
            cursor: auto;
        }
        @include breakpoints(responsive) {
            bottom: 5px;
            left: 10px;
        }
    }
    &__arrow-right {
        font-size: 28px;
        right: -14px;
        bottom: -50px;
        position: absolute;
        cursor: pointer;
        &--disabled {
            cursor: auto;
        }
        @include breakpoints(responsive) {
            bottom: 5px;
            right: 10px;
        }
    }
}
