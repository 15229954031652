@import '../../@sochi-components/@styles/variables';

.ProjectDumpLoadDeclaration {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 4px -4px;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;

    &__content {
        background-color: $color-white;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 2rem 3rem;
        padding: 36px 16px;
        overflow: auto;
        align-items: center;
        align-content: center;

        &.hidden-overflow {
            overflow: hidden !important;
        }
    }

    &__asterisk {
        width: 10px;
        height: 10px;
        margin: -12px 0 0 2px;
    }

    &__emptyInput {
        input {
            border: 1px solid $color-red !important;
        }
    }

    &__yes-no-checkbox {
        display: flex;
        gap: 10px;
        align-items: center;
        align-content: center;

        @include breakpoints(responsive) {
            flex-wrap: wrap;
        }

        &-description {
            font-size: 12px;
            width: 66%;

            @include breakpoints(responsive) {
                width: 100%;
                font-size: 14px;
            }
        }
    }

    &__buttons {
        padding: 16px;
    }

    &__declaration-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        grid-gap: 10px;

        @include breakpoints(responsive) {
            grid-template-columns: 1fr;
        }
    }

    &__declaration-title {
        font-size: 12px;
        font-weight: bold;
    }

    &__declaration-number {
        grid-column: 2 / span 2;

        @include breakpoints(desktop) {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            font-size: 12px;
            font-weight: bold;

            .SochiInput {
                max-width: 100px;
                margin-left: 10px;
            }
        }

        @include breakpoints(responsive) {
            grid-column: 1;
        }
    }

    &__producer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
        align-content: start;
        align-items: start;

        &-name {
            grid-column: 1 / span 2;

            @include breakpoints(responsive) {
                grid-column: 1 / span 3;
            }
        }

        &-number {
            @include breakpoints(responsive) {
                grid-column: 1 / span 3;
            }
        }

        &-address {
            grid-column: 1 / span 3;
        }

        &-zip {
            @include breakpoints(responsive) {
                grid-column: 1 / span 1;
            }
        }

        &-place {
            grid-column: 2 / span 2;
        }

        &-reference {
            grid-column: 1 / span 2;

            @include breakpoints(responsive) {
                grid-column: 1 / span 3;
            }
        }

        &-phone {
            @include breakpoints(responsive) {
                grid-column: 1 / span 3;
            }
        }

        &-organization-number {
            grid-column: 1 / span 3;
        }
    }

    &__receiver {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
        align-content: start;
        align-items: start;

        &-invoice {
            grid-column: 1 / span 3;
        }

        &-address {
            grid-column: 1 / span 3;
        }

        &-zip {
            @include breakpoints(responsive) {
                grid-column: 1 / span 1;
            }
        }

        &-place {
            grid-column: 2 / span 2;
        }

        &-reference {
            grid-column: 1 / span 2;

            @include breakpoints(responsive) {
                grid-column: 1 / span 3;
            }
        }

        &-phone {
            @include breakpoints(responsive) {
                grid-column: 1 / span 3;
            }
        }

        &-organization-number {
            grid-column: 1 / span 3;
        }
    }

    &__main-info {
        grid-column: 1 / span 2;
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;

        @include breakpoints(responsive) {
            grid-column: 1;
        }
    }

    &__waste {
        &-municipality {
            grid-column: 2 / span 2;

            @include breakpoints(responsive) {
                grid-column: 1;
            }
        }

        &-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 10px;

            @include breakpoints(responsive) {
                grid-template-columns: 1fr;
            }
        }
    }

    &__assessments {
        grid-column: 1 / span 2;
        font-size: 12px;
        display: grid;
        grid-template-columns: 66% 1fr;
        align-items: center;
        align-content: center;
        grid-column-gap: 10px;
        grid-row-gap: 10px;

        @include breakpoints(responsive) {
            grid-column: 1;
            grid-template-columns: 1fr;
            font-size: 14px;
        }

        &-description {
            grid-column: 1 / span 1;
            grid-row: 1 / span 2;

            @include breakpoints(responsive) {
                grid-row: 1;
                grid-column: 1;
            }
        }

        &-checkbox {
            grid-column: 2 / span 1;

            @include breakpoints(responsive) {
                grid-column: 1;
            }
        }
    }

    &__pollution-type {
        grid-column: 1 / span 2;

        @include breakpoints(responsive) {
            grid-column: 1;
        }
    }

    &__process {
        grid-column: 1 / span 2;

        @include breakpoints(responsive) {
            grid-column: 1;
        }
    }

    &__reason {
        grid-column: 1 / span 2;

        @include breakpoints(responsive) {
            grid-column: 1;
        }
    }

    &__consistency-and-risks {
        grid-column: 1 / span 2;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

        @include breakpoints(responsive) {
            grid-template-columns: 1fr;
            grid-column: 1;
        }
    }

    &__consistency {
        grid-column: 1;

        &-title {
            font-size: 12px;

            @include breakpoints(responsive) {
                font-size: 18px;
            }
        }

        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
        align-items: start;

        &-ovrit {
            display: flex;
            gap: 10px;
            align-self: end;
        }
    }

    &__risks {
        grid-column: 2 / span 2;
        display: grid;
        grid-template-columns: 1fr 1fr 1.5fr;
        align-content: center;
        gap: 10px;

        @include breakpoints(responsive) {
            grid-column: 1;
            grid-template-columns: 1fr;
        }

        &-title {
            font-size: 12px;
            grid-column: 1 / span 3;

            @include breakpoints(responsive) {
                font-size: 18px;
                grid-column: 1;
            }
        }

        @include breakpoints(responsive) {
            grid-column: 1;
        }

        &-acid,
        &-alk {
            display: grid;
            gap: 10px;
            grid-template-columns: 80px 1fr;
        }

        &-hygiene {
            grid-column: 2 / span 2;

            @include breakpoints(responsive) {
                grid-column: 1;
            }
        }

        &-stability {
            grid-column: 1 / span 3;

            @include breakpoints(responsive) {
                grid-column: 1;
            }
        }
    }

    &__wastes {
        grid-column: 1 / span 2;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 10px;
        margin-top: 10px;

        @include breakpoints(responsive) {
            grid-column: 1;
            grid-template-columns: repeat(2, 1fr);
        }

        &-title {
            grid-column: 1 / span 6;
            font-size: 12px;

            @include breakpoints(responsive) {
                grid-column: 1 / span 2;
                font-size: 12px;
            }
        }
    }

    &__total-wastes {
        grid-column: 1 / span 2;
        grid-gap: 10px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: start;

        @include breakpoints(responsive) {
            grid-column: 1;
            grid-template-columns: 1fr;
        }

        &-title {
            grid-column: 1 / span 2;
            font-size: 12px;

            @include breakpoints(responsive) {
                grid-column: 1;
                font-size: 18px;
            }
        }

        &-info {
            grid-column: 1 / span 2;

            @include breakpoints(responsive) {
                grid-column: 1;
            }

            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
        }

        &-amount {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;

            @include breakpoints(responsive) {
                grid-template-columns: 1fr;
            }
        }

        &-per-delivery {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;

            @include breakpoints(responsive) {
                grid-template-columns: 1fr;
            }
        }

        &-additional {
            grid-column: 1 / span 2;
            grid-gap: 10px;
            display: grid;

            &-date {
                max-width: 150px;
            }
        }
    }

    &__transportation {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        align-content: center;
        padding-top: 2px;

        &-tunk,
        &-container,
        &-rails,
        &-info-attached {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;

            span {
                font-size: 12px;

                @include breakpoints(responsive) {
                    font-size: 14px;
                }
            }
        }
    }

    &__input-label {
        font-size: 12px;

        @include breakpoints(responsive) {
            font-size: 14px;
        }

        font-weight: 400;
    }
}
