@import '../../@styles/variables';

.FilesInput {
    &__files-container {
        padding-left: 20px;
        margin-bottom: 10px;
    }

    &__file-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__file-name {
        @include Ellipsis(calc(100% - 40px));
    }

    &__input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
}
