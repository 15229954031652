@import '../../../../../@sochi-components/@styles/variables';

.SuccessOfferDialog {
    padding: 10px 20px 20px;

    @include breakpoints(tablet) {
        padding: 0;
    }

    &__subtitle {
        margin-left: 20px !important;
        margin-right: 20px !important;

        @include breakpoints(tablet) {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    &__comment-column {
        @include Ellipsis(20vw);
    }

    &__table {
        @include TableInModal;
    }
}
