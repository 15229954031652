@import '../../@styles/variables';

.GaugeChart {
    margin-bottom: 35px;
    position: relative;
    @include breakpoints(responsive) {
        margin-bottom: 0;
    }

    svg {
        width: 188px;
        @include breakpoints(responsive) {
            width: 137px;
        }
        margin: 0 auto;
    }

    &__diagram-line {
        stroke: $color-green;
        stroke-width: 12px;
    }

    &__diagram-line-background {
        stroke-width: 12px;
        stroke: transparentize($color-blue-dark, 0.9);
    }

    &__percents {
        font-size: 42px;
        line-height: 1em;
        color: $color-black;
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translate(-50%);
        @include breakpoints(responsive) {
            font-size: 38px;
            @include Ellipsis(100px);
        }
    }
}
