@import '../../../../@sochi-components/@styles/variables.scss';
.ProjectForm {
    width: 100%;
    height: calc(100vh - 120px);

    @include breakpoints(responsive) {
        height: calc(100vh - 50px);
    }

    &--edit {
        height: calc(100vh - 200px);
    }

    &__fields {
        padding-bottom: 15px;
    }

    &__divider {
        width: 100%;
        height: 1px;
        display: block;
        background: $color-blue-dark;
        opacity: 0.4;
        margin-top: 8px;
        margin-bottom: 16px;
        @include breakpoints(smartphone) {
            display: none;
        }
    }
    &__form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    &__input-container {
        margin-bottom: 16px;
    }
    &__continue {
        align-self: flex-start;
    }
}

@keyframes slideInLeft {
    0% {
        width: 0;
        opacity: 0;
    }

    60% {
        width: 240px;
        opacity: 0.2;
    }

    100% {
        opacity: 1;
        width: 400px;
    }
}
