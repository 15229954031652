@import '../@styles/variables';

.SochiTitle {
    margin: 40px 0 20px;
    display: flex;
    gap: 12px;
    @include breakpoints(responsive) {
        margin: 20px 0 8px;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .SochiSwitch {
        @include breakpoints(smartphone) {
            width: 100%;
        }
    }
    &__title {
        order: -1;
        font-size: 28px;
        line-height: 40px;
        margin: 0;
        flex-grow: 2;
        font-weight: bold;
        color: $color-text-black;
        font-family: $catamaran;
        justify-content: flex-end;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include breakpoints(responsive) {
            font-size: 24px;
            line-height: 1em;
        }
        @include breakpoints(tablet) {
            max-width: 100%;
        }
    }
}
