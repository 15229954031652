.SochiLocationEdit {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    &__lat-lng {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }

    &__part {
        width: 100%;
    }
}
