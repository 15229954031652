@import '../@styles/variables';

.MapWithContentContainer {
    &__desktop {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    &__mobile {
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__side {
        @include BlackShadow;
        padding: 20px 20px 0;
        width: 374px;
        overflow-y: auto;
    }

    &__desktop-map {
        flex-grow: 1;
        height: 100%;
    }
}
