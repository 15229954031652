@import '../../../../@sochi-components/@styles/variables';

@mixin SlideContent {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    transition: opacity 1s linear;
    opacity: 1;
    img {
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
.LoginCarousel {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: black;

    &__slide-content {
        @include SlideContent;
    }

    &__slide-content--hidden {
        @include SlideContent;
        opacity: 0;
        transition: opacity 1s linear;
    }

    &__information {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 110px;
        background: linear-gradient(180deg, rgba(238, 238, 238, 0.01) 57.06%, #131415 100%);
    }
    &__title {
        margin-left: 68px;
        text-align: left;
        font-weight: bold;
        font-size: 28px;
        line-height: 20px;
        color: $color-text-white;
        margin-bottom: 16px;
    }
    &__text {
        margin-left: 68px;
        text-align: left;
        color: $color-text-white;
        font-size: 18px;
        line-height: 22px;
        max-width: 430px;
    }
}
