@import '../../../../../@sochi-components/@styles/variables';

.ProjectStatus {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-top: 4px;
    flex-shrink: 1;

    &--ongoing {
        color: $color-status-orange;
    }

    &--completed {
        color: $color-status-green;
    }

    &--booked {
        color: $color-black-transparent;
    }
}
