@import '../../../../@sochi-components/@styles/variables';

.TermsAndConditions {
    padding: 40px;
    @include breakpoints(responsive) {
        padding: 20px;
    }

    &__terms {
        max-height: 600px;
        overflow-y: scroll;
        margin-bottom: 25px;
        @include breakpoints(smartphone) {
            max-height: calc(100vh - 100px);
        }
    }

    &__buttons {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
}
