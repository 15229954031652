@import '../../../../../@sochi-components/@styles/variables';

.TopBarLogo {
    margin-left: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    @include breakpoints(responsive) {
        display: none;
    }

    &__brand-sign {
        height: 100%;
    }

    &__logotype {
        height: 30px;
        margin-left: 33px;

        color: $color-white;
        font-size: 32px;
        font-family: $catamaran;
        letter-spacing: 16px;
    }
}
