@import '../../../@sochi-components/@styles/variables';

.ConfirmEmailPage {
    overflow: hidden;
    position: fixed;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    user-select: none;

    &__confirm-email-container {
        border-radius: 0;
        margin: 0;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        position: fixed;
        background-color: rgb(245, 245, 245);
        top: 30%;
        left: 50%;
        width: 800px;
        height: 120px;
        margin-left: -400px;
        padding: 20px;
        font-size: 16pt;
        text-align: center;
    }

    &__confirm-email-panel {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &__background {
        filter: blur(10px);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        min-width: 50%;
        min-height: 50%;
    }
}
