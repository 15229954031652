@import '../../../../../@sochi-components/@styles/variables';

.LandfillCreateForm {
    width: 100%;
    height: calc(100vh - 120px);

    @include breakpoints(responsive) {
        height: calc(100vh - 50px);
    }

    &__map {
        width: 100%;
        height: 100%;
    }

    &__back-button {
        position: absolute;
        top: 0;
    }

    &__divider {
        width: 100%;
        height: 1px;
        display: block;
        background: $color-blue-dark;
        opacity: 0.4;
        margin-bottom: 20px;
        margin-top: 20px;
        @include breakpoints(smartphone) {
            display: none;
        }
    }

    &__form-control {
        margin-bottom: 16px;
    }

    &__previous-step-button {
        color: $color-white !important;
        background: $color-gray !important;
        &:hover {
            color: $color-blue-dark !important;
            background-color: $color-white !important;
        }
    }

    &__stepper {
        margin-bottom: 20px;
    }
}
