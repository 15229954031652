@import '../../../../../@sochi-components/@styles/variables';
.MassSchedules {
    width: 100%;
    display: flex;
    background: $color-white;
    border-top: 2px solid $color-blue-dark;
    padding: 30px;
    justify-content: space-between;
    @include breakpoints(responsive) {
        width: auto;
        border: none;
        background: none;
        padding: 0;
        flex-wrap: wrap;
        flex-direction: column;
    }
    &__info {
        width: 340px;
        @include breakpoints(responsive) {
            background: $color-white;
            padding: 5px 10px;
            width: auto;
            margin-bottom: 10px;
        }
    }
    &__info-item {
        font-size: $font-size-main;
        color: $color-black;
        margin-bottom: 6px;
        font-weight: bold;
        font-family: $catamaran;
        @include breakpoints(responsive) {
            font-size: 14px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &__title {
            color: $color-black-transparent;
            font-weight: normal;
        }
    }
}
