@import '../../../../@sochi-components/@styles/variables';

.EditSubstanceDropdown {
    margin-bottom: 20px;
    position: relative;
    display: grid;
    grid-template-columns: 24px 2fr 1fr;
    grid-gap: 10px;
    grid-row-gap: 0;
    align-items: center;

    @include breakpoints(responsive) {
        grid-template-columns: 20px 2fr 1fr;
    }

    &__parent-substance {
        &--has-children {
            grid-row-gap: 30px;
            display: grid;
            grid-template-columns: 1fr;
            position: relative;

            @include breakpoints(responsive) {
                grid-row-gap: 15px;
            }

            &::after {
                content: '';
                height: 100%;
                width: 2px;
                background: $color-white;
                display: block;
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        & .SochiInput {
            margin: 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;

            label {
                @include SimpleLabel;
                margin-bottom: 0;
                @include Ellipsis(100%);
            }
        }
        .SochiInput__input--readonly {
            background: rgba(0, 0, 0, 0.04);
            border: 2px solid white;
        }
    }

    &__children-substances {
        grid-row-gap: 30px;
        display: grid;
        grid-template-columns: 1fr;
        justify-content: end;
        padding-left: 20px;

        & .SochiInput {
            grid-gap: 20px;

            @include breakpoints(responsive) {
                grid-gap: 10px;
            }
        }

        @include breakpoints(responsive) {
            grid-row-gap: 15px;
            padding-left: 10px;
        }
    }

    &__sochi-large-dropdown-field {
        padding: 40px 20px 30px;
        background: $color-gray-dark;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        width: 100%;
        grid-column: 2 / span 2;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 30px;
        grid-column-gap: 16px;

        @include breakpoints(responsive) {
            padding: 15px 10px;
            grid-row-gap: 15px;
            grid-column-gap: 10px;
        }
    }

    &__buttons-block {
        width: 100%;
        display: flex;
        grid-column: 1 / span 2;
        justify-content: flex-end;

        & button {
            margin: 0 0 0 10px;
        }
    }

    &__sochi-dropdown-buttons-group {
        display: flex;
        flex-direction: row;

        & .sochi-checkbox-group {
            height: 100%;
            margin-top: 10px;
        }

        & .dropdown-button {
            flex: 3;
        }

        & .SochiContaminationLevelSelect {
            flex: 1;
            margin-left: 5px;
            display: block !important;
        }
    }

    &__dropdown-button {
        font-family: $catamaran;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none !important;
        outline: none;
        display: flex;
        flex: 1;
        border-radius: 0;
        padding: 11px 16px;
        font-size: 18px;
        line-height: 22px;
        color: $color-text-black-transparent;
        border: 1px solid $color-white;
        height: 44px;
        width: 100%;
        cursor: pointer;
        background-color: $color-white;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        position: relative;

        &--disabled {
            color: $color-gray;
            border-color: $color-gray-light-2;
            background-color: $color-gray-light-4;
            cursor: default;
        }
    }

    &__dropdown-button-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: normal;
    }

    &__dropdown-button-arrow {
        position: absolute;
        top: calc(50% - 11px);
        right: 7px;
        color: $color-blue;

        &--rotated {
            transform: rotate(180deg) translateY(3px);
        }
    }
}
