@import '../../../../@sochi-components/@styles/variables';

.ProjectSchedule {
    @include pageWrapper;

    & .SochiInfoPanel {
        margin-bottom: 0;
    }

    &__edit-schedule {
        @include breakpoints(small-smartphone) {
            width: 60%;
        }
    }
}
