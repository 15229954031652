@import '../../../../../@sochi-components/@styles/variables';

.AddEditMassForm {
    width: 100%;

    @include breakpoints(desktop) {
        min-width: 1000px;
    }

    &__title {
        @include modalTitle;
        margin-top: 16px;
        margin-bottom: 12px;
    }

    &__subtitle {
        @include breakpoints(desktop) {
            width: 650px;
        }
    }

    &__buttons-block {
        margin-top: 20px;
        display: flex;
        gap: 12px;
        justify-content: flex-end;
    }

    &__buttons-spacer {
        flex-grow: 1;
    }

    &__request-quote {
        display: flex;
        flex-direction: row-reverse;
    }
}
