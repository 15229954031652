@import '../../../../@sochi-components/@styles/variables';

.AuthText {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: $color-white;
    margin-bottom: 23px;
    @include breakpoints(responsive) {
        font-size: 14px;
    }

    &--transparent {
        opacity: 0.6;
    }
}
