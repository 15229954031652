@import '../../../../../@sochi-components/@styles/variables';

.ProjectMassSchedule {
    &__project-max-schedule__title {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__button-contained {
        background-color: $color-white !important;
        color: $color-blue !important;
    }
}
