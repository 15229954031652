@import '../../@styles/variables';

.MassCard {
    min-width: 250px;
    max-width: 338px;
    width: 100%;
    padding: 26px 20px;
    background: $color-white;
    border-top: 2px solid $color-blue-dark;
    @include BlackShadow;
    font-family: $catamaran;
    text-align: center;

    @include breakpoints(responsive) {
        padding: 16px 14px;
    }

    &__head {
        font-size: 18px;
        line-height: 22px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        @include breakpoints(responsive) {
            flex-direction: column;
            justify-content: center;
            font-size: 14px;
        }
    }

    &__id {
        color: $color-black-transparent;
        text-align: left;

        @include breakpoints(responsive) {
            text-align: center;
        }
    }

    &__status {
        font-weight: bold;
        text-align: right;

        @include breakpoints(responsive) {
            text-align: center;
        }

        &--orange {
            color: $color-orange;
        }

        &--green {
            color: $color-green;
        }

        &--gray {
            color: $color-black-transparent;
        }

        &--black {
            color: $color-black;
        }

        &--blue {
            color: $color-blue;
        }
    }

    &__title {
        text-align: center;
        margin: 8px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        height: 86px;

        @include breakpoints(responsive) {
            height: 46px;
        }
    }

    &__name-container {
        display: flex;
        gap: 8px;
        justify-content: center;
        width: 100%;
        color: $color-black;
        font-size: 28px;
        font-weight: bold;
        line-height: 1em;
        margin-bottom: 6px;

        @include breakpoints(responsive) {
            font-size: 24px;
            margin-bottom: 2px;
        }
    }

    &__name {
        @include Ellipsis(auto);
    }

    &__test-result {
        color: $color-red-dark;
    }

    &__comment {
        font-size: 18px;
        color: $color-black;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include breakpoints(responsive) {
            font-size: 14px;
        }
    }

    &__data {
        font-size: 18px;
        line-height: 22px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: $color-black-transparent;
        text-align: center;
        margin-bottom: 28px;

        @include breakpoints(responsive) {
            flex-direction: column;
            font-size: 14px;
            margin-bottom: 4px;
        }

        b {
            display: block;
            font-size: 28px;
            margin-top: 8px;
            color: $color-black;

            @include breakpoints(responsive) {
                font-size: 24px;
                margin-top: 0;
                margin-bottom: 4px;
            }
        }
    }

    &__button {
        margin: 0;
        font-weight: bold;
    }
}
