@import '../@styles/variables';

.ImagesInput {
    display: grid;
    flex-wrap: wrap;
    margin-top: 16px;
    grid-template-columns: repeat(auto-fill, minmax(133px, 1fr));
    grid-gap: 20px;
    @include breakpoints(responsive) {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }

    &__image-box {
        position: relative;
        overflow: hidden;
        width: 133px;
        height: 133px;
        @include breakpoints(responsive) {
            height: 100px;
            width: 100px;
        }
    }

    &__image {
        height: 100%;
        width: 100%;
        cursor: pointer;
        transition: opacity 0.1s linear, transform 0.1s linear;
        &:hover {
            transform: scale(1.1);
            opacity: 0.9;
        }
    }

    &__image-icon {
        position: absolute;
        z-index: 1;
        bottom: 4px;
        left: 8px;
        height: 24px;
    }

    &__image-icon-shadow {
        position: absolute;
        bottom: 3px;
        left: 9px;
    }

    &__image-loading {
        height: 100%;
        width: 100%;
        border: 2px solid $color-blue;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__image-error {
        position: relative;
        height: 100%;
        width: 100%;
        border: 2px solid $color-red;

        &:before {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 25%;
            border: 2px solid $color-red;
            content: '';
        }

        &:after {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 25%;
            border: 2px solid $color-red;
            content: '';
        }
    }

    &__input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    &__input-label {
        position: relative;
        display: block;
        margin: 16px auto;
        width: 100px;
        height: 100px;
        cursor: pointer;
        border: 2px solid $color-blue;
        transition: background-color 0.1s linear, transform 0.1s linear;
        @include breakpoints(responsive) {
            width: 86px;
            height: 86px;
            margin: 7px auto;
        }
        &:hover {
            background-color: transparentize($color-blue, 0.95);
            transform: scale(1.1);
        }

        &:before {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 25%;
            border: 2px solid $color-blue;
            content: '';
        }

        &:after {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(90deg);
            width: 25%;
            border: 2px solid $color-blue;
            content: '';
        }
    }

    &__remove-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        background-color: $color-blue;
        cursor: pointer;
        transition: transform 0.1s linear;
        transform-origin: top right;

        &--red {
            background-color: $color-red;
        }

        &:hover {
            transform: scale(2);
        }

        &:before {
            display: block;
            position: absolute;
            width: 66%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            border: 1px solid $color-white;
            content: '';
        }

        &:after {
            display: block;
            position: absolute;
            width: 66%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            border: 1px solid $color-white;
            content: '';
        }
    }
}
