@import '../@styles/variables';

.SochiMessage {
    font-size: 16px;
    padding: 0 15px;
    color: $color-white;
    margin: 0;
    min-height: 40px;
    box-shadow: 0 2px 2px $color-black-transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    @include breakpoints(responsive) {
        flex-direction: column;
    }
    @include breakpoints(responsive) {
        font-size: 12px;
        letter-spacing: 0.5px;
        padding: 4px 10px;
    }
    &--SUCCESS {
        background: $color-alert-success;
    }
    &--ERROR {
        background: $color-alert-error;
    }
    &--INFO {
        background: $color-alert-info;
    }

    &__button {
        &:hover {
            background: $color-white !important;
        }

        position: absolute;
        top: 0;
        right: -10px;

        @include breakpoints(smartphone) {
            position: relative;
            margin-top: 10px;
        }
    }
}
