@import '../../@styles/variables';

.DialogContent {
    padding: 40px;
    font: $catamaran;
    font-size: 18px;
    line-height: 1.2;
    height: 100%;

    @include breakpoints(responsive) {
        padding: 10px;
    }
}
