@import '../../../../../@sochi-components/@styles/font';

.AccountForm {
    margin: 40px;
    display: flex;
    flex-direction: column;

    &__form {
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-size: 24px;
        lineheight: 1.1;
        margin: 21px 0 10.5px;
    }

    &__buttons {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 24px;
    }
}
