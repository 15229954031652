@import '../../@styles/variables';

.DialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 40px 0;
    max-width: 100%;

    @include breakpoints(responsive) {
        padding: 20px 10px 0;
    }

    &--has-close-btn {
        max-width: calc(100% - 48px);
    }

    &__title {
        margin: 0;
        font-size: 28px;
        word-break: break-all;

        @include breakpoints(responsive) {
            font-size: 24px;
        }
    }
    &__secondary-info {
        color: $color-gray;
        font-family: $catamaran;
        @include breakpoints(responsive) {
            font-size: 14px;
        }
    }
}
