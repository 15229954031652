@import '../@styles/variables';

.SochiPdfPreview {
    height: calc(90vh - 260px);
    margin: 0;
    width: 100%;
    min-width: 80vw;

    @include breakpoints(responsive) {
        height: calc(100vh - 220px);
    }

    &__pdf-container {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    &__message {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--in-popup {
        width: 100%;
        min-width: 0;
        height: calc(100% - 40px);
        margin: 20px 0 0 20px;

        &__pdf-container {
            margin: 0;
            border: none;
        }
    }

    &--in-container {
        width: 100%;
        min-width: 0;
        height: 100%;
    }

    &--in-container &__pdf-container {
        margin: 0;
        border: none;
        overflow-y: unset;
    }

    &--in-container &__message {
        padding-top: 200px;
        margin: auto;
    }
}
