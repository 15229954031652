@import '../../../../@sochi-components/@styles/variables';

.GlobalLoader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparentize($color-white, 0.7);
    opacity: 0;
    transition: opacity 0.2s linear;
    overflow: hidden;

    &--showing {
        height: 100%;
        opacity: 1;
    }

    &__loader-container {
        width: 150px;
        height: 50px;
        background: $color-white;
        box-shadow: 0 0 20px 20px $color-white;
    }
}
