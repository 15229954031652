@import '../../../../../@sochi-components/@styles/variables';

.TopBarMenuItem {
    text-align: center;
    margin: 0 35px;
    cursor: pointer;
    position: relative;

    @include breakpoints(responsive) {
        margin: 0 12px;
    }

    &:hover {
        text-decoration: none;
    }
    &__icon {
        display: block;
        font-size: 28px;
        height: 28px;
        margin-top: -4px;
        @include breakpoints(responsive) {
            font-size: 19px;
            height: 19px;
            margin-top: -2px;
        }
    }

    &__name {
        font-size: 14px;
        color: $color-text-white;
        font-weight: normal;
        display: block;
        line-height: 1em;
        margin-top: 9px;
        @include breakpoints(responsive) {
            font-size: 10px;
            margin-top: 3px;
        }
    }

    &__badge {
        background: $color-red;
        position: absolute;
        border-radius: 10px 10px 10px 0;
        top: -16px;
        left: 34px;
        padding: 0 5px;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        font-family: $catamaran;
        color: $color-white;

        @include breakpoints(responsive) {
            top: -8px;
            left: 20px;
            font-size: 11px;
            line-height: 11px;
            padding: 1px;
            min-width: 12px;
        }
    }
}
