@import '../../../../../@sochi-components/@styles/variables';

.OtherDocuments {
    padding-bottom: 60px;
    &__other-documents-cards {
        @include DocumentCardsWrapper;
    }
    &__crop-cards {
        @include DocumentCropCards;
    }
}
