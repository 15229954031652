@import '../@styles/variables';

.SochiRadioButton {
    display: flex;
    align-items: flex-end;

    &__input {
        height: 24px;
        width: 24px;
        @include breakpoints(responsive) {
            height: 20px;
            width: 20px;
        }
    }

    &__label {
        margin-left: 9px;
        margin-bottom: 0;
        font-size: 18px;
        font-family: $catamaran;
        font-weight: bold;
        @include breakpoints(responsive) {
            font-size: 14px;
        }
    }
}
