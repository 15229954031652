@import '../../../../../@sochi-components/@styles/variables';

.ProjectNotes {
    @include breakpoints(small-smartphone) {
        width: 350px;
        height: 160px;
        margin-bottom: 40px;
    }

    &__notes-header {
        display: flex;
        justify-content: space-between;
        padding: 20px 0 0 0;

        @include breakpoints(small-smartphone) {
            padding: 20px 40px 0 0;
        }
    }

    &__notes-title {
        margin: 20px 0 20px;
    }

    &__notes-edit {
        margin-top: 20px !important;
    }

    &__notes-text {
        width: 100% !important;
        height: 100%;
        -moz-appearance: none;
        outline: 0 none transparent;
        border: 0 none transparent;
        color: $color-text-black;
        background-color: transparent;
        resize: none;
        overflow: auto;
        box-sizing: border-box;
        cursor: text !important;
        &:focus {
            outline: 0;
        }
    }

    &__notes-wrapper {
        font-size: 18px;

        @include breakpoints(responsive) {
            font-size: 16px;
        }

        @include breakpoints(small-smartphone) {
            width: 310px;
        }
    }

    &__no-content {
        width: 100%;
        padding-top: 5%;
    }

    &__sochi-card {
        @include SimpleCard($color-blue-dark);
        min-height: 168px;
        margin: 0;
        padding: 20px;

        @include breakpoints(responsive) {
            min-height: 130px;
        }

        &__title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
        }

        &__body {
            display: flex;
            justify-content: center;

            &-info {
                @include InfoPartOfCard;
            }
        }
    }
}
