@import '../../../@sochi-components/@styles/variables';
.ForgotPassword {
    &__form {
        @include AuthForm;
        @include DarkBackgroundInputLabel;
    }
    &__buttons {
        margin-bottom: 30px;
        margin-top: 15px;
    }
}
