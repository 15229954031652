@import 'colors';
@import 'media';
@import 'font';
@mixin SimpleCard($border-color) {
    width: 100%;
    background: $color-white;
    border-top: 2px solid $border-color;
}

@mixin DocumentCardsWrapper {
    display: grid;
    grid-gap: 16px;
    width: 100%;
    grid-template-columns: repeat(auto-fill, 220px);
    min-height: 233px;
    @include breakpoints(responsive) {
        grid-template-columns: repeat(auto-fill, 165px);
        grid-gap: 10px;
        min-height: 140px;
    }
}

@mixin DocumentCropCards {
    max-height: 242px;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    @include breakpoints(responsive) {
        max-height: 154px;
    }
}

@mixin NavigationCard($background: $color-white) {
    display: block;
    background: $background;
    border-radius: 8px;
    padding: 14px 16px;

    font-size: 18px;
    font-family: $catamaran;

    mark {
        background: none;
        font-weight: 600;
        color: $color-blue;
        text-decoration: underline;
        padding: 0;
        line-height: 0.2em;
    }

    &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
    }

    &__name {
        font-weight: bold;
        line-height: 22px;
        color: $color-black;
        @include Ellipsis(280px);

        @include breakpoints(smartphone) {
            @include Ellipsis(250px);
        }
    }

    &__body {
        padding: 10px 0 10px 40px;
    }

    &__section {
        display: block;
        min-height: 24px;
        line-height: 24px;
        color: $color-gray-dark-2;
        position: relative;

        & + & {
            margin-top: 8px;
        }

        &-icon {
            position: absolute;
            left: -41px;
            color: $color-gray-light-2;
            top: 0;
            font-size: 22px !important;
        }

        .LandfillStatusIcon__icon {
            position: absolute;
            left: -40px;
            width: 20px;
            top: 2px;
            text-align: center;
        }

        &--isClickable {
            cursor: pointer;

            &:hover {
                color: $color-black;
                text-decoration: underline;

                .ProjectCard__section-icon {
                    color: $color-black;
                }
            }
        }
    }

    &__action {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include breakpoints(responsive) {
            align-items: stretch;
        }
    }

    &__location {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}

@mixin SideBarCardsWrapper($height: calc(100% - 113px), $responsive-height: calc(100% - 100px)) {
    height: $height;
    overflow: auto;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    grid-gap: 10px;
    padding: 5px;
    margin: -5px;
    @include breakpoints(responsive) {
        height: $responsive-height;
    }
    @include breakpoints(tablet) {
        grid-template-columns: 1fr 1fr;
    }
}
@mixin InfoPartOfCard {
    background: none;
    padding: 0;
    margin: 0;
    color: $color-black-transparent;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    display: flex;
    text-align: center;
    max-width: calc(100% - 60px);
    @include breakpoints(responsive) {
        font-size: 12px;
    }
    &:before {
        content: 'i';
        font-size: 18px;
        height: 22px;
        width: 22px;
        color: $color-white;
        border-radius: 100%;
        display: block;
        background: $color-black-transparent;
        position: absolute;
        left: -28px;
        top: 0;
    }
}

@mixin MassCardsWrapper {
    display: grid;
    margin-right: 16px;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(338px, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 28px;
    @include breakpoints(responsive) {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        margin-right: 0;
        grid-row-gap: 16px;
    }
}
