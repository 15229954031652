@import '../@styles/variables';

.DocumentPreview {
    font-family: $catamaran;
    font-size: 18px;
    line-height: 1.2;
    height: 100%;
    max-height: calc(100vh - 168px);
    overflow: auto;
    display: flex;

    padding: 40px;

    @include breakpoints(responsive) {
        padding: 10px;
        max-height: calc(100vh - 48px);
    }

    &__image {
        max-height: 100%;
        max-width: 100%;
        margin: auto;
    }

    &--with-pdf {
        .SochiPdfPreview {
            width: 456px;
            min-width: 0;
            height: 624px;
            margin: auto;

            @include breakpoints(responsive) {
                height: auto;
                max-height: 624px;
            }

            &__pdf-container {
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
            }
        }
    }
}
