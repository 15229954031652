@import '../../../../../../../@sochi-components/@styles/variables';

.WorkTimeSelect {
    font-family: $catamaran;
    display: flex;
    flex-direction: column;

    &__day-checkbox {
        margin-bottom: 0;
    }

    &__day-checkbox-label {
        display: flex;
        width: 100%;
    }

    &__day-name {
        flex: 1;
    }

    &__day-time {
        margin-left: auto;
    }

    &__day-container {
        &:last-child {
            margin-bottom: -10px;
        }

        &--is-open {
            .WorkTimeSelect__day-time {
                opacity: 0.4;
            }
        }
    }

    &__day-form {
        padding-top: 10px;
    }

    &__form-section {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        margin-bottom: 8px;
    }

    &__form-checkbox {
        margin-top: 16px;
        margin-left: 16px !important;
    }

    &__form-checkbox-label {
        color: $color-text-black-transparent;
    }
}
