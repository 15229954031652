@import '../@styles/variables';

.SochiDivider {
    width: 100%;
    height: 1px;
    display: block;
    background: $color-blue-dark;
    opacity: 0.4;
    margin-bottom: 20px;
    margin-top: 20px;

    &--dense {
        margin-bottom: 10px;
        margin-top: 10px;
    }
}
