@import '../../@styles/variables';

.LightBox {
    &__image-container {
        position: relative;
        width: 100vw;
        height: 100vh;
        cursor: pointer;
    }

    &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
    }

    &__caption {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 16px;
        top: 0;
        background-color: transparentize($color-black, 0.3);
        color: $color-white;
        font-weight: bold;
        font-size: 20px;
    }
}
