@import '../../../../../@sochi-components/@styles/variables';

.UserMenu {
    display: block;
    position: absolute;
    background: $color-white;
    padding: 6px 40px 25px 38px;
    box-shadow: -2px 2px 10px rgba(20, 27, 77, 0.3);
    width: 100%;
    min-width: min-content;
    top: 90px;
    left: 0;
    z-index: $z-index-dropdown;
    animation: openMenu;
    animation-duration: 0.2s;
    animation-timing-function: ease-in-out;
    overflow: hidden;

    @include breakpoints(responsive) {
        padding: 5px 25px 15px 25px;
        top: 44px;
        width: auto;
    }

    &__list {
        padding: 0;
        margin: 0;
    }

    &__list-item {
        list-style: none;
        box-sizing: border-box;
        margin-top: 18px;
        white-space: nowrap;

        &:last-child {
            margin-top: 36px;

            a {
                i {
                    font-size: 18px;
                    height: 18px;
                    width: 18px;
                }
            }
        }

        &:hover {
            a {
                color: $color-blue;
            }
        }

        a {
            font-size: 18px;
            color: $color-black;
            display: flex;
            align-items: center;
            line-height: 22px;

            @include breakpoints(responsive) {
                font-size: 14px;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__list-item-icon {
        display: inline-block;
        font-size: 28px;
        margin-right: 18px;
        width: 28px;
        height: 28px;

        @include breakpoints(responsive) {
            font-size: 22px;
            height: 22px;
            width: 22px;
            margin-right: 8px;
        }
    }
}
