@import '../../../../../@sochi-components/@styles/variables';

.LandfillDeclarations {
    &__waste-cards {
        @include DocumentCardsWrapper;
    }
    &__crop-cards {
        @include DocumentCropCards;
    }
}
