@import '../../../../../../@sochi-components/@styles/variables';
.ProjectTransportScheduleContainer {
    max-width: 100%;
    overflow: visible;
    padding: 30px 40px;
    font-family: $catamaran;

    @include breakpoints(responsive) {
        overflow: auto;
        min-height: calc(100vh - 94px);
    }
}
