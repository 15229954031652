@import '../../../../../../@sochi-components/@styles/variables';

.DumpLoadBlock {
    display: flex;
    align-items: flex-start;

    &__dumpload-title {
        width: 200px;
        background: transparentize($color-black, 0.9);
        text-align: right;
        padding: 5px 16px;
        line-height: 22px;
        position: relative;
        margin: 12px 0;
        cursor: pointer;

        &--readonly {
            color: $color-gray;
            cursor: initial;
        }
    }

    &__dumpload-name {
        width: 100%;
        display: flex;
        gap: 4px;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 600;

        &--error {
            color: $color-red;
        }
    }

    &__dumpload-name-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &__declaration-number {
        color: $color-black-transparent;
    }

    &__dumpload-comment {
        position: absolute;
        right: 0;
        padding: 4px 10px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &__dumpload-container {
        display: flex;
        flex-wrap: wrap;
        border-left: 1px solid $color-blue-dark;
        border-right: 1px solid $color-blue-dark;
        width: calc(100% - 200px);
        min-height: 56px;

        & .cell {
            width: 110px;
            border-right: 1px dotted $color-blue-dark;
            padding: 12px 0px;

            &:last-child {
                border-right: none;
            }

            & input {
                border: none;
                background: transparentize($color-black, 0.8);
                font-size: 18px;
                font-weight: 600;
                text-align: center;
                padding: 1px 16px;
                line-height: 22px;
                color: $color-black;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: textfield;
                width: 100%;
                height: 32px;
                border-radius: 0;

                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    -moz-appearance: textfield;
                    margin: 0;
                }

                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    -moz-appearance: textfield;
                    margin: 0;
                }
            }
        }

        & .filler {
            width: 110px;
            height: 56px;
            border-right: 1px dotted $color-blue-dark;

            &:last-child {
                border-right: none;
            }
        }

        & .offset {
            width: 110px;
            height: 32px;
            margin: 12px 0;
            background: transparentize($color-black, 0.9);
            position: relative;

            &:after {
                content: '';
                border-right: 1px dotted $color-blue-dark;
                height: calc(100% + 24px);
                right: 0;
                position: absolute;
                top: -12px;
            }
        }
    }

    &__schedule-edit-block {
        flex-basis: 100%;
        display: flex;
        padding: 24px 8px;
        margin: -12px 0px;

        & .info-block {
            display: flex;
            flex-direction: column;

            & .info-block-text {
                font-size: 18px;
                font-weight: 600;
                text-align: left;
                line-height: 30px;
                color: $color-black;
                width: 100%;
            }

            & .button-container {
                align-self: stretch;
                display: flex;
                align-items: center;
                height: 46px;

                & button {
                    min-width: 100%;
                }
            }
        }
    }
}
