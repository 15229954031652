@import '../../../../../@sochi-components/@styles/variables';

.ProjectPriceCalculator {
    @include pageWrapper;

    overflow-x: hidden;

    :disabled {
        cursor: not-allowed;
    }

    &__document-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;

        @include breakpoints(responsive) {
            grid-template-columns: 1fr;
        }

        @include breakpoints(tablet) {
            grid-template-columns: 1fr 1fr;
        }

        & .sochi-card {
            @include SimpleCard($color-blue-dark);
            min-height: 168px;
            margin: 0;
            padding: 20px;

            @include breakpoints(responsive) {
                min-height: 130px;
            }

            & .sochi-card-title {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;

                & label {
                    font-size: 18px;
                    margin-right: 15px;
                    margin-bottom: 0;
                }

                & .SingleDatePicker {
                    max-width: 168px;
                    margin: 0 5px;
                    height: 40px;

                    & .SingleDatePickerInput {
                        height: 100%;
                        border: 1px solid $color-blue-dark !important;
                        background: $color-white;

                        & .DateInput {
                            width: 100% !important;
                        }

                        & input {
                            height: 40px;
                            background: $color-white;
                            font-size: 18px;

                            &::placeholder {
                                color: $color-black-transparent;
                            }
                        }
                    }
                }
            }

            & .sochi-card-body {
                display: flex;
                justify-content: center;

                & .sochi-info {
                    @include InfoPartOfCard;
                    flex-direction: column;
                }
            }
        }
    }

    &__transport-wrapper {
        display: grid;
        grid-template-rows: 100px 1fr;
        grid-template-columns: 1fr;

        @include breakpoints(responsive) {
            grid-template-rows: 52px 1fr;
        }
    }

    &__notes-header {
        display: flex;
        justify-content: space-between;
        padding: 20px 0 0 0;

        @include breakpoints(small-smartphone) {
            padding: 20px 40px 0 0;
        }
    }

    &__notes-title {
        margin: 20px 0 20px;
    }

    &__notes-edit {
        margin-top: 20px;
    }

    &__notes-text {
        width: 100%;
    }

    &__notes-wrapper {
        text-align: left;
        font-size: 18px;
        justify-content: start;
        overflow-y: auto;
        height: 200px;

        @include breakpoints(small-smartphone) {
            width: 310px;
            height: 130px;
        }
    }

    &__notes-edit-form__close {
        width: 97%;
    }

    &__no-content {
        width: 100%;
        padding-top: 5%;
    }
}
