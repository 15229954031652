@import '../../../../@sochi-components/@styles/variables';

.AuthWrapper {
    user-select: none;
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-template-rows: 66px 1fr;
    min-height: 100vh;

    font-family: $catamaran;
    position: relative;
    grid-template-areas:
        'logo slider'
        'form slider';

    @include breakpoints(smartphone) {
        grid-template-columns: 1fr;
        grid-template-rows: 50px 1fr;
        min-height: auto;
    }

    &__logotype {
        grid-column: 1 / span 1;
        grid-row: 1;
        background-color: $color-white;
        width: 400px;
        padding: 8px 20px 8px 200px;
        display: flex;
        justify-content: right;
        align-items: center;

        img {
            max-width: 100%;
        }

        @include breakpoints(smartphone) {
            width: auto;
        }
    }

    &__form-wrapper {
        grid-column: 1 / span 1;
        grid-row: 2;
        width: 100%;
        background-color: $color-blue-dark;
        background-image: url('../../assets/back-bow.png');
        background-size: 100% 100%;
        background-position: bottom left;
        padding: 100px 50px 266px;
        position: relative;

        @include breakpoints(smartphone) {
            padding: 40px 20px;
        }
    }

    &__back-button-wrapper {
        @include breakpoints(desktop) {
            position: absolute;
            top: 30px;
        }
    }

    &__carousel-wrapper {
        grid-column: 2;
        grid-row: 1 / span 2;

        @include breakpoints(smartphone) {
            display: none;
        }
    }
}
