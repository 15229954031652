@import 'colors';
@import 'media';
@import 'font';
@mixin SimpleInput() {
    font-family: $catamaran;
    border-radius: 0;
    padding: 11px 16px;
    font-size: 18px;
    line-height: 44px;
    color: $color-black;
    border: none;
    height: 44px;
    display: block;
    width: 100%;
    @include breakpoints(responsive) {
        padding: 11px 11px;
        font-size: 14px;
    }
    &[type='number'] {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        appearance: none;
    }
    &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        appearance: none;
    }
    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        appearance: none;
    }

    &:disabled,
    &[readonly] {
        color: transparentize($color-black, 0.6);
    }
}

@mixin SimpleLabel() {
    font-family: $catamaran;
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
    text-align: left;
    display: block;
    margin: 0;
    @include breakpoints(responsive) {
        font-size: 14px;
        line-height: 1.5;
    }
    &.dark {
        color: $color-black;
    }
    &.light {
        color: $color-white;
    }
}
