@import '../@styles/variables';

.Modal {
    &__modal-container {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: $color-black-overlay-transparent;
        z-index: $z-index-modal;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10vh;
        overflow: auto;

        &--no-padding {
            padding-top: 0;
        }
    }
}
