@import 'colors';
@import 'media';

$catamaran: 'Catamaran', sans-serif;
$font-size-main: 18px;
$font-weight-bold: 600;
$font-weight-regular: 400;

@mixin Ellipsis($width) {
    display: block;
    max-width: $width;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $catamaran;
}

@mixin modalTitle {
    font-family: $catamaran;
    font-size: 28px;
    color: $color-black;
    margin: 35px 0;
    font-weight: 600;
    @include breakpoints(responsive) {
        margin: 13px 0;
        font-size: 24px;
    }
}

@mixin InlineLabel() {
    color: $color-gray;
    font-size: 14px;
    line-height: 18px;
}
