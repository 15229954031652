@import 'colors';
@import 'media';
@import 'font';

$table-cell-height-mobile: 40px;

@mixin table-checkbox-cell {
    padding: 22px 0 22px 16px;
    @include breakpoints(responsive) {
        padding: 10px 0;
        line-height: 1em;
        position: sticky;
        height: 40px;
        min-width: 30px;
        left: 0;
        display: table-cell;
        .SochiCheckbox {
            margin: 0 5px;
        }
    }
}
@mixin TableInModal {
    .MuiPaper-root {
        background: transparent;
    }

    tr td {
        background: transparent;
    }

    tr:last-child td {
        border-bottom-color: $color-blue-dark-transparent !important;
    }
}
