@import '../../../../@sochi-components/@styles/variables';

.AuthTitle {
    &__title {
        color: $color-text-white;
        font-size: 28px;
        font-weight: 600;
        line-height: 1.5em;
        margin-top: 0;
        margin-bottom: 30px;
        @include breakpoints(responsive) {
            font-size: 24px;
            margin-bottom: 14px;
        }
    }
    &__subtitle {
        color: $color-text-white-transparent;
    }
}
