@import '../../../../@sochi-components/@styles/variables';

.AuthDivider {
    height: 1px;
    display: block;
    background: $color-white-transparent;
    margin-top: 23px;
    margin-bottom: 40px;
    @include breakpoints(responsive) {
        margin: 20px 0;
    }
}
