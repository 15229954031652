@import '../../../../@sochi-components/@styles/variables';

.LandfillOverview {
    @include pageWrapper;

    display: flex;
    flex-direction: column;
    gap: 20px;

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
