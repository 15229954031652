@import 'media';
@import 'colors';
@import 'z-index';
@import 'animations';
@import 'shadows';

@mixin buttonsBlock {
    margin-top: 40px;
    display: grid;
    align-items: center;
    justify-content: end;
    grid-template-columns: repeat(auto-fit, minmax(0px, max-content));
    grid-column-gap: 12px;
    width: 100%;
}

@mixin pageWrapper() {
    background: $color-gray-background;
    position: relative;
    padding: 0 20px 20px;
    @include breakpoints(responsive) {
        padding: 0 10px 20px;
    }
}
