//base colors
$color-white: #fff;
$color-black: #000;
$color-blue-dark: #141b4d;
$color-blue: #1a73e9;
$color-red: #ff5404;
$color-red-dark: #ee2737;
$color-blue-light: #81b7ff;
$color-blue-extra-light: #cde2ff;
$color-cyan: #1e90ff;
$color-gray: #828282;
$color-orange: #f4a720;
$color-orange-status: #feae22;
$color-carrot: #ef640a;
$color-green: #00aa55;
$color-gray-light: #e4e4e4;
$color-gray-light-2: #bdbdbd;
$color-gray-light-3: #ededed;
$color-gray-light-4: #e0e0e0;
$color-gray-background: #eeefef;
$color-gray-dark: #d8d8d8;
$color-gray-dark-2: #4f4f4f;
$color-gray-dark-3: #999999;
$color-transparent: #0000;

// transparent colors
$color-white-transparent: transparentize($color-white, 0.6);
$color-black-transparent: transparentize($color-black, 0.6);
$color-black-overlay-transparent: transparentize($color-black, 0.4);
$color-blue-dark-transparent: transparentize($color-blue-dark, 0.6);
$color-blue-transparent: transparentize($color-blue, 0.6);
$color-blue-transparent-btn: transparentize($color-blue, 0.4);

// text-colors
$color-text-black: $color-black;
$color-text-black-transparent: $color-black-transparent;
$color-text-blue: $color-blue;
$color-text-white: $color-white;
$color-text-white-transparent: transparentize($color-text-white, 0.4);

// status-colors
$color-status-black: $color-black;
$color-status-blue: $color-blue;
$color-status-green: $color-green;
$color-status-orange: $color-orange-status;
$color-status-gray: $color-gray;

// background-colors

// entity colors
$color-unavailable-input: transparentize($color-blue-dark, 0.95);

// alert colors
$color-alert-error: $color-carrot;
$color-alert-success: $color-green;
$color-alert-info: $color-cyan;

//status colors
$color-status-black: $color-black;
$color-status-blue: $color-blue-light;
$color-status-gray: $color-gray;
$color-status-green: $color-green;
$color-status-orange: $color-orange;

// charts colors
$color-chart-black: $color-black;
$color-chart-blue: $color-blue;
$color-chart-green: $color-green;
$color-chart-orange: $color-orange;
$color-chart-gray: $color-gray-dark;
$color-chart-black-transparent: transparentize($color-black, 0.6);
$color-chart-blue-transparent: transparentize($color-blue, 0.6);
$color-chart-green-transparent: transparentize($color-green, 0.6);
$color-chart-orange-transparent: transparentize($color-orange, 0.6);
$color-chart-gray-transparent: transparentize($color-gray, 0.6);

// shame colors
$color-unavailable-input: transparentize($color-blue-dark, 0.95);
