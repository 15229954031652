@import '../../../../@sochi-components/@styles/variables';

.MessageContainer {
    height: 0;
    overflow: visible;

    &--global {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: $z-index-message;
    }

    &--in-page {
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: $z-index-inpage-message;
    }

    &-enter {
        opacity: 0;
        transform: translateY(-50px);
    }

    &-enter-active {
        transform-origin: top;
        opacity: 1;
        transform: translateY(0);
        transition: opacity 200ms, transform 200ms;
    }

    &-exit {
        opacity: 1;
        transform: translateY(0);
    }

    &-exit-active {
        transform-origin: top;
        opacity: 0;
        transform: translateY(-50px);
        transition: opacity 200ms, transform 200ms;
    }
}
