@import '../../../../../@sochi-components/@styles/variables';

.MaterialsColumn {
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoints(smartphone) {
        width: auto;
    }

    &:first-child {
        margin-right: 10px;

        @include breakpoints(smartphone) {
            margin-right: 0;
        }
    }

    &:last-child {
        margin-left: 10px;

        @include breakpoints(smartphone) {
            margin-left: 0;
        }
    }

    &__title {
        width: 100%;
    }
}
