@import '../../../@sochi-components/@styles/variables';

.Empty {
    padding: 32px 16px;
    margin-top: 16px;
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid $color-gray-background;
    font-size: 1.2em;
    border-radius: 2px;
}
