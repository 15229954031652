@import '../@styles/variables';

.Stepper {
    position: relative;

    &__step-title {
        color: $color-text-black-transparent;
        font-weight: bold;

        &--left {
            text-align: left;
        }

        &--center {
            text-align: center;
        }
    }
}
