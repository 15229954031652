@import '../../../@sochi-components/@styles/variables';

.NotFoundPage {
    position: relative;
    height: 100vh;

    &__notfound {
        max-width: 920px;
        width: 100%;
        line-height: 1.4;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;

        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    &__notfound-404 {
        position: absolute;
        height: 100px;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        z-index: -1;
    }

    h1 {
        font-family: Roboto, Helvetica, sans-serif;
        color: #ececec;
        font-weight: 900;
        font-size: 276px;
        margin: 0px;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    h2 {
        font-family: Roboto, Helvetica, sans-serif;
        font-size: 46px;
        color: #000;
        font-weight: 900;
        text-transform: uppercase;
        margin: -8px 0 0;
    }

    p {
        font-family: Roboto, Helvetica, sans-serif;
        font-size: 16px;
        color: #000;
        font-weight: 400;
        text-transform: uppercase;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &__link {
        color: $color-cyan;
    }

    @media only screen and (max-width: 480px) {
        h1 {
            font-size: 162px;
        }

        h2 {
            font-size: 26px;
        }
    }
}
