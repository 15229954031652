@import '../../../../@sochi-components/@styles/variables';

.Masses {
    display: flex;
    flex-wrap: wrap;
    flex-basis: calc(100% - 358px);
    grid-column: 1;

    &__cards {
        @include MassCardsWrapper;
    }
}
