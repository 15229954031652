@import '../../../../@sochi-components/@styles/variables';

.ProjectLandfillLists {
    &__filters {
        display: grid;
        margin-bottom: 20px;
        justify-content: space-between;
        grid-template-columns: 260px 40px;
        grid-column-gap: 20px;

        @include breakpoints(responsive) {
            margin-bottom: 10px;
            grid-template-columns: 1fr 30px;
        }
    }

    &__options {
        height: 40px;

        @include breakpoints(responsive) {
            height: 30px;
        }
    }

    &__add-button.MuiIconButton-root {
        height: 40px;
        font-size: 28px;
        background-color: $color-blue;

        @include breakpoints(responsive) {
            height: 30px;
            font-size: 18px;
        }

        &:hover {
            background-color: $color-blue-transparent-btn;
        }
    }
}
