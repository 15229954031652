@import '../../../@sochi-components/@styles/variables';

.LoginPage {
    &__form {
        @include AuthForm;
        @include DarkBackgroundInputLabel;
    }
    &__forgot-password {
        font-size: 18px;
        line-height: 1.2em;
        color: $color-white-transparent;
        cursor: pointer;
        letter-spacing: 0.2px;
        transition: color 0.2s ease-in-out;
        text-align: right;
        display: block;
        @include breakpoints(responsive) {
            font-size: 14px;
        }
        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }
    &__create-button {
        color: $color-white !important;
        background: $color-gray !important;
        &:hover {
            color: $color-blue-dark !important;
            background-color: $color-white !important;
        }
    }
}
