@import '../../../../../@sochi-components/@styles/variables';

.ProjectAdditionalDocuments {
    &__image {
        padding: 10px 20px 20px 20px;
        background-color: $color-gray-light-3;
        max-width: 600px;
        max-height: 800px;
        text-align: center;

        @include breakpoints(small-smartphone) {
            max-width: 340px;
            max-height: 600px;
        }
    }

    &__image img {
        margin-top: 10px;
        background-color: $color-white;
        max-width: 560px;
        max-height: 650px;

        @include breakpoints(small-smartphone) {
            max-width: 320px;
            max-height: 540px;
        }
    }

    &__file-preview__close {
        text-align: right;
        padding-top: 20px;
    }

    &__documents {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        max-height: 166px;
        overflow-x: auto;

        @include breakpoints(responsive) {
            height: auto;
            max-height: 150px;
        }
    }

    &__mobile-documents {
        @include breakpoints(small-smartphone) {
            width: 325px;
            margin-top: 50px;
        }
    }

    &__documents-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 100px 1fr;
        max-height: 307px;

        @include breakpoints(responsive) {
            grid-template-rows: 84px 1fr;
        }

        @include breakpoints(smartphone) {
            grid-template-rows: 125px 1fr;
        }
    }

    &__documents-header {
        display: flex;
        justify-content: space-between;
        padding: 20px 0 0;

        @include breakpoints(smartphone) {
            flex-wrap: wrap;
        }
    }

    &__documents-title {
        flex-shrink: 1;
        margin: 20px 0;

        @include Ellipsis(auto);

        @include breakpoints(smartphone) {
            width: 100%;
            margin-bottom: 0;
        }

        @include breakpoints(small-smartphone) {
            width: 100%;
            margin-bottom: 15px;
        }
    }

    &__documents-buttons {
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;

        @include breakpoints(smartphone) {
            width: 100%;
            padding-bottom: 10px;
            justify-content: space-between;
        }

        @include breakpoints(small-smartphone) {
            width: 310px;
            justify-content: space-between;
        }
    }

    &__documents-open-files {
        margin-top: 20px !important;
        margin-right: 20px;
        font-family: 'Catamaran', sans-serif;
        font-size: 18px;
        line-height: 1.7;
        text-align: center;
        border-radius: 30px;
        color: $color-blue;
        border: 1px solid $color-blue;
        background: transparent;
        box-sizing: border-box;
        padding: 4px 30px;
        height: 40px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.21);

        @include breakpoints(responsive) {
            height: 30px;
            font-size: 14px;
            padding: 3px 18px;
            margin-right: 5px;
        }

        @include breakpoints(small-smartphone) {
            margin: 0 !important;
        }
    }

    &__documents-open-files:hover {
        color: $color-white;
        background-color: $color-blue;
        text-decoration: none;
        transition: all 0.1s ease-in-out;
    }

    &__documents-add-file {
        margin-top: 20px !important;

        @include breakpoints(small-smartphone) {
            margin: 0 !important;
        }
    }

    &__documents-item {
        font-size: 18px;
        padding-bottom: 5px;
        padding-right: 5px;
        max-width: 45%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        page-break-inside: avoid;
        break-inside: avoid;

        @include breakpoints(responsive) {
            font-size: 16px;
        }
    }

    &__documents-item-icon {
        padding-right: 10px;
    }

    &__empty-content {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}
