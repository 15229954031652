$xs-max: 359px;
$sm: 360px;
$sm-max: 767px;
$md: 768px;
$md-max: 1239px;
$lg: 1240px;
$xl: 1920px;

/**
    Match to MUI

    large-desktop       theme.breakpoints.up('xl')
    desktop             theme.breakpoints.up('lg')
    responsive          theme.breakpoints.down('md')
    tablet              theme.breakpoints.only('md')
    smartphone          theme.breakpoints.down('sm')
    small-smartphone    theme.breakpoints.down('xs')
 */
@mixin breakpoints($type) {
    @if $type == large-desktop {
        @media (min-width: $xl) {
            @content;
        }
    } @else if $type == desktop {
        @media (min-width: $lg) {
            @content;
        }
    } @else if $type == responsive {
        // responsive like tablet but don't have bottom line
        // as discussed max-height point was removed
        @media (max-width: $md-max) {
            @content;
        }
    } @else if $type == tablet {
        @media (min-width: $md) and (max-width: $md-max) {
            @content;
        }
    } @else if $type == smartphone {
        @media (max-width: $sm-max) {
            @content;
        }
    } @else if $type == small-smartphone {
        @media (max-width: $xs-max) {
            @content;
        }
    }
}
