.Loader {
    &--fill-form {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        text-align: center;
        z-index: 100;
        background-color: rgba(255, 255, 255, 0.5);

        // .loader-background {
        //     position: relative;
        //     width: 100%;
        //     height: 100%;
        //     background-color: white;
        //     z-index: 100;
        // }
        .loading {
            z-index: 101;
            font-weight: bolder;
        }
    }
}
