@import '../../../../@sochi-components/@styles/variables';

.FloatMobilePanel {
    border-radius: 6px 6px 0 0;
    height: calc(100% - 10px);
    width: calc(100% - 68px);
    background: $color-gray-light;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 8px 12px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    &--closed {
        height: 96px;
    }

    &__open-button {
        cursor: pointer;
        font-size: 18px;
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        &--open {
            transform: rotate(0) translateY(4px);
        }
    }

    &__content {
        overflow-y: scroll;
    }
}
