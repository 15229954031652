@import 'media';
@import 'colors';

@mixin AuthForm {
    display: flex;
    flex-direction: column;

    @include breakpoints(responsive) {
        padding: 20px 0;
        gap: 16px;
    }
}

@mixin DarkBackgroundInputLabel {
    .MuiFormControl-root {
        & .MuiFormLabel-root.MuiInputLabel-outlined {
            color: $color-white;
        }

        & .MuiFormHelperText-root.Mui-error {
            color: $color-orange;
        }
    }
}
