@import '../../../../../@sochi-components/@styles/variables.scss';

.DeclarationFormModal {
    &__button-group {
        display: flex;
        flex-wrap: nowrap;
        gap: 12px;
        justify-content: flex-end;
        margin-top: 40px;
    }

    &__button-close {
        margin-right: auto !important;
    }

    .ProjectDumpLoadDeclaration {
        position: relative;
        height: calc(100% - 20vh);
        box-shadow: none;
        background: none;

        @include breakpoints(responsive) {
        }

        &__content {
            background: none;
            margin: 0;
            padding: 0;

            @include breakpoints(responsive) {
                margin: 0;
                padding: 0;
            }
        }
    }

    & .SochiInput {
        margin-bottom: 0;
    }
}
