@keyframes slideInLeftSideBar {
    0% {
        width: 0;
        opacity: 0;
    }

    60% {
        width: 240px;
        opacity: 0.2;
    }

    100% {
        opacity: 1;
        width: 400px;
    }
}
