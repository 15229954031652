@import '../../../../../../@sochi-components/@styles/variables';

.ProjectTransportScheduleColumns {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 12px;

    &__first-column {
        width: 200px;
    }

    &__column {
        width: 110px;
        color: $color-text-black-transparent;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
    }

    &__arrow-left {
        position: absolute !important;
        left: 175px;
    }

    &__arrow-right {
        position: absolute !important;
        right: -25px;
    }
}
