@import '../../@styles/variables';

.DocumentCard {
    background: $color-white;
    border-top: 2px solid $color-blue-dark;
    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: 38px minmax(22px, auto) 22px minmax(16px, auto) auto 1fr;

    width: 220px;
    padding: 24px 10px;
    margin: 0;
    min-height: 242px;

    font-family: $catamaran;
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 0.2px;

    @include breakpoints(responsive) {
        grid-template-rows: 28px minmax(16px, auto) 16px minmax(12px, auto) auto 1fr;

        padding: 10px;
        min-height: 154px;
        width: 165px;
        font-size: 12px;
    }

    &__status {
        grid-row: 1;
        text-align: center;

        font-weight: bold;
        width: 100%;

        line-height: 1.1;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: normal;

        @include breakpoints(responsive) {
            line-height: 1.15;
            margin-bottom: 0;
        }

        &--green {
            color: $color-status-green;
        }

        &--orange {
            color: $color-status-orange;
        }

        &--gray {
            color: $color-status-gray;
        }

        &--black {
            color: $color-status-black;
        }

        &--blue {
            color: $color-status-blue;
        }
    }

    &__number {
        grid-row: 2;
        width: 100%;
        text-align: center;
        font-weight: bold;
        color: $color-black;

        overflow: hidden;
        word-break: break-all;
        white-space: pre-wrap;
        line-height: 22px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        @include breakpoints(responsive) {
            line-height: 1em;
        }
    }

    &__info {
        grid-row: 3;
        text-align: center;
        @include Ellipsis(100%);
    }

    &__name {
        grid-row: 4;
        width: 100%;
        text-align: center;
        color: $color-black;

        overflow: hidden;
        white-space: pre-wrap;
        line-height: 22px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        @include breakpoints(responsive) {
            @include Ellipsis(100%);
            font-size: 14px;
        }
    }

    &__addon {
        grid-row: 5;
        text-align: center;
        @include Ellipsis(100%);
    }

    &__buttons {
        grid-row: 6;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: flex-end;
        flex-wrap: wrap;

        gap: 16px;

        @include breakpoints(responsive) {
            gap: 8px;
        }

        .MuiButton-root {
            margin: 0;

            @include breakpoints(responsive) {
                font-size: 12px;
            }
        }
    }
}
