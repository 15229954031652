@import '../@styles/variables';

.SochiSearchInput {
    width: 100%;
    position: relative;

    &__sochi-search-input {
        background: $color-white;
        font-size: 18px;
        line-height: 20px;
        border-radius: 20px;
        padding: 5px 24px;
        width: 100%;
        border: none;
        font-family: $catamaran;
        @include breakpoints(responsive) {
            font-size: 14px;
            line-height: 16px;
            padding: 3px 18px;
        }
    }

    &__sochi-icon {
        position: absolute;
        right: 10px;
        top: calc(50% - 12px);
        height: 24px;
        font-size: 24px;
        @include breakpoints(responsive) {
            top: calc(50% - 9px);
            height: 18px;
            font-size: 18px;
        }
    }

    &__erase-icon {
        cursor: pointer;
    }
}
