@import '../../../../@sochi-components/@styles/variables';
.DeviationTable {
    &__description-column {
        @include Ellipsis(30vw);
        min-width: 30vw;
    }

    &__issue-column {
        @include Ellipsis(20vw);
    }

    &__table {
        max-height: calc(100vh - 100px);
        overflow-y: scroll;

        @include breakpoints(responsive) {
            max-height: calc(100vh - 150px);
        }
    }
}
