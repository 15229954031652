@import '../../../../../@sochi-components/@styles/variables';

.AnalysisDocuments {
    padding: 24px;

    &__table {
        @include TableInModal;
    }

    &__table-document-name {
        @include Ellipsis(50vw);

        @include breakpoints(tablet) {
            @include Ellipsis(75vw);
        }
    }

    &__button-group {
        justify-content: space-between;
    }
}
