@import '../../../../@sochi-components/@styles/variables';

.TopBar {
    background: $color-blue-dark;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    padding: 0;
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    z-index: $z-index-global-topmenu;

    @include breakpoints(responsive) {
        grid-template-columns: 1fr;
        padding: 8px 0;
    }

    &__menu-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__personItem {
        display: grid;
        align-items: center;
        margin-left: 60px;
        margin-right: 100px;
        cursor: pointer;
        position: relative;
        grid-template-columns: 60px 1fr;
        grid-column-gap: 18px;

        @include breakpoints(responsive) {
            margin-right: 0;
            padding-right: 40px;
            margin-left: 40px;
            grid-template-columns: 1fr;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__personalInfo {
        color: $color-white;
        position: relative;
        font-size: 18px;
        display: block;

        @include breakpoints(responsive) {
            margin-left: 0;
        }
    }

    &__caret {
        display: block;
        position: absolute;
        right: -46px;
        top: -4px;
        font-size: 29px;
        height: 29px;

        @include breakpoints(responsive) {
            font-size: 25px;
            height: 25px;
            right: -24px;
            top: -1px;
        }

        &--opened {
            transform: rotate(180deg);
        }
    }

    &__name {
        font-size: 14px;
        @include Ellipsis(150px);

        @include breakpoints(responsive) {
            @include Ellipsis(150px);
            min-width: 100px;
        }
    }

    &__company {
        font-size: 14px;
        @include breakpoints(responsive) {
            font-size: 10px;
        }
    }

    &__avatar {
        height: 60px;
        width: 60px;
        display: block;

        @include breakpoints(responsive) {
            display: none;
        }
    }

    &__disc {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        text-transform: uppercase;
        color: $color-white;
        border-radius: 100%;
        background: $color-blue-light;
    }
}

@keyframes openMenu {
    from {
        max-height: 0;
    }

    to {
        max-height: 400px;
    }
}
